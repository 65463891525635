import { convertStrapiResponsiveImage } from 'helpers/RecipeHelpers';

import type {
  IAccountNewFeature,
  IAccountNewFeatureData,
  IAccountNewFeatureSlide,
  IAccountNewFeatureSlideData
} from 'types';

export function toAccountNewFeature(data: IAccountNewFeatureData): IAccountNewFeature {
  const {
    id,
    analyticsLabel,
    title,
    slug,
    slides,
  } = data || {};

  return {
    id,
    analyticsLabel,
    title,
    slug,
    slides: slides.map((slide) => toAccountNewFeatureSlide(slide))
  };
}

export function toAccountNewFeatureSlide(data: IAccountNewFeatureSlideData): IAccountNewFeatureSlide {
  const {
    analyticsLabel,
    title,
    heading,
    description,
    confirmBtn,
    actionBtn,
    actionLink,
    image,
  } = data || {};

  const {
    web
  } = image || {};

  return {
    analyticsLabel,
    title,
    heading,
    description,
    confirmBtn,
    actionBtn,
    actionLink,
    image: convertStrapiResponsiveImage(web)
  };
}

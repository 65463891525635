import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import {
  Carousel, Error, Markdown, Modal, ModalActions, ResponsiveImage, Skeleton
} from 'components/ui';
import { parseAbsoluteUrl } from 'helpers/UrlHelpers';
import { useInView } from 'hooks/common/useInView';
import { useAccountNewFeature } from 'hooks/data/useAccountNewFeature';
import { useAnalytics } from 'hooks/useAnalytics';

import type { CarouselApi } from 'components/ui/Carousel';
import type {
  IAccountNewFeature, IAccountNewFeatureNotification, IAccountNewFeatureSlide, Nullable
} from 'types';

import styles from './FeatureOnboardingModal.module.scss';

interface Props {
  className?: string,
  //
  isOpen: boolean,
  //
  featureId: Nullable<IAccountNewFeatureNotification['featureId']>,
  //
  onClose: (shouldDismiss: boolean) => void,
  onAfterClose?: () => void
}

const FeatureOnboardingModal = (props: Props) => {

  const {
    className,
    //
    isOpen,
    //
    featureId,
    //
    onClose,
    onAfterClose,
  } = props;

  // Hooks

  const analytics = useAnalytics();

  const {
    isSuccess, isLoading, isError, data: feature
  } = useAccountNewFeature(featureId, {
    enabled: !!isOpen
  });

  const { analyticsLabel } = feature || {};

  // Handlers

  const handleClose = () => {
    if (onClose) {
      onClose(isSuccess);
    }
  };

  const handleAfterClose = () => {
    if (onAfterClose) {
      onAfterClose();
    }
  };

  // Effects

  React.useEffect(() => {
    if (featureId && analyticsLabel) {
      analytics.openNewFeatureModal(featureId, analyticsLabel);
    }
  }, [featureId, analyticsLabel]);

  // Render

  return (
    <Modal
      isOpen={isOpen}
      //
      className={classnames(styles.root, className)}
      contentClassName={styles.modalContent}
      innerClassName={styles.modalInner}
      //
      withPadding={false}
      withCloseButton
      priority={5}
      //
      headerProps={{
        sticky: true
      }}
      desktopProps={{
        position: 'center',
      }}
      responsiveProps={{
        position: 'bottom',
      }}
      shouldCloseOnOverlayClick={false}
      //
      close={handleClose}
      onAfterClose={handleAfterClose}
    >
      {
        isLoading
          ? <LoadingState />
          : (
            isError
              ? <Error />
              : (
                <OnboardingSlider
                  feature={feature}
                  onAction={handleClose}
                  onClose={handleClose}
                />
              )
          )
      }
    </Modal>
  );
};

// Components

// --- Content

interface OnboardingSliderProps {
  feature: IAccountNewFeature,
  onAction: () => void,
  onClose: () => void
}

const OnboardingSlider = (props: OnboardingSliderProps) => {
  const {
    feature,
    onAction,
    onClose,
  } = props;

  // Props

  const {
    id: featureId,
    slides,
    analyticsLabel: featureAnalyticsLabel
  } = feature || {};

  // Hooks

  const analytics = useAnalytics();
  const router = useRouter();

  // State Hooks

  const [imageCarouselApi, setImageCarouselApi] = React.useState<CarouselApi>();
  const [contentCarouselApi, setContentCarouselApi] = React.useState<CarouselApi>();

  // Handlers

  // --- Buttons

  const handleConfirmClick = () => {
    const slideIndex = imageCarouselApi.selectedScrollSnap();
    const total = slides?.length;

    if (slideIndex === total - 1) {
      onClose();
    } else {
      imageCarouselApi.scrollNext();
    }
  };

  const handleCTAClick = (link: string) => {
    const slideIndex = imageCarouselApi.selectedScrollSnap();
    const { analyticsLabel: slideAnalyticsLabel } = slides[slideIndex];

    const { valid, pathname } = parseAbsoluteUrl(link);

    if (valid) {
      router.push(pathname);
    }

    analytics.selectNewFeatureModalCTA(featureId, featureAnalyticsLabel, slideIndex, slideAnalyticsLabel);

    onAction();
  };

  // --- Carousel

  const handleSlideEnter = (slideIndex: number, slide: IAccountNewFeatureSlide) => {
    const { analyticsLabel: slideAnalyticsLabel } = slide || {};

    analytics.viewNewFeatureModalSlide(featureId, featureAnalyticsLabel, slideIndex, slideAnalyticsLabel);

    if (slideIndex === (slides?.length || 0) - 1) {
      analytics.completeNewFeatureModalSlides(featureId, featureAnalyticsLabel);
    }
  };

  const handleSlideSelect = React.useCallback(() => {
    if (!imageCarouselApi) return;
    const index = imageCarouselApi.selectedScrollSnap();
    contentCarouselApi.scrollTo(index);
  }, [imageCarouselApi, contentCarouselApi]);

  const onImageCarouselInit = React.useCallback((api: CarouselApi) => {
    setImageCarouselApi(api);
    api.on('select', handleSlideSelect);
  }, [handleSlideSelect]);

  const onContentCarouselInit = React.useCallback((api: CarouselApi) => {
    setContentCarouselApi(api);
  }, []);

  // Render

  return (
    <div className={styles.slider}>

      {/* Image Carousel */}
      <Carousel
        prevClassName={classnames(styles.navButton, styles.prev)}
        nextClassName={classnames(styles.navButton, styles.next)}
        paginationClassName={classnames(styles.pagination)}
        spaceBetween={0}
        slidesPerView={1}
        slidesPerGroup={1}
        navigation
        pagination
        onInit={onImageCarouselInit}
      >
        {
          slides.map((slide, index) => (
            <ImageSlide
              key={`image-slide-${index}`}
              slide={slide}
            />
          ))
        }
      </Carousel>

      {/* Content Carousel */}
      <Carousel
        spaceBetween={0}
        slidesPerView={1}
        slidesPerGroup={1}
        drag={false}
        onInit={onContentCarouselInit}
      >
        {
          slides.map((slide, index) => (
            <ContentSlide
              key={`content-slide-${index}`}
              slide={slide}
              onConfirmClick={handleConfirmClick}
              onCTAClick={handleCTAClick}
              onEnter={() => {
                handleSlideEnter(index, slide);
              }}
            />
          ))
        }
      </Carousel>

    </div>
  );
};

// --- Image Slide

interface ImageSlideProps {
  slide: IAccountNewFeatureSlide
}

const ImageSlide = (props: ImageSlideProps) => {
  const {
    slide
  } = props;

  const { title, image } = slide || {};
  const { desktop, mobile } = image || {};

  return (
    <ResponsiveImage
      className={styles.image}
      breakpoint="sm"
      desktopProps={{
        ...desktop,
        alt: title,
        style: {
          objectFit: 'cover',
        },
      }}
      responsiveProps={{
        ...mobile,
        title,
        style: {
          objectFit: 'cover',
        },
      }}
    />
  );
};

interface ContentSlideProps {
  slide: IAccountNewFeatureSlide,
  onConfirmClick: () => void,
  onCTAClick: (link: string) => void,
  onEnter: (slide: IAccountNewFeatureSlide) => void
}

const ContentSlide = (props: ContentSlideProps) => {
  const {
    slide,
    onConfirmClick,
    onCTAClick,
    onEnter
  } = props;

  // Props

  const {
    title,
    heading,
    description,
    actionBtn,
    actionLink,
    confirmBtn
  } = slide || {};

  // Hooks

  const { observe } = useInView({
    threshold: 0.75,
    onEnter: ({ unobserve }) => {
      if (onEnter) {
        onEnter(slide);
      }
      unobserve();
    }
  });

  // Render

  return (
    <div
      ref={onEnter ? observe : null}
      className={styles.content}
    >
      <div className={styles.main}>
        <div className={styles.title}>{title}</div>
        <div className={styles.separator} />
        <div className={styles.heading}>{heading}</div>
        <div className={styles.description}>
          <Markdown text={description} />
        </div>
      </div>
      <div className={styles.actions}>
        <ModalActions
          withBorder={false}
          withPadding={false}
          primary={{
            text: confirmBtn,
            onClick: () => onConfirmClick()
          }}
          secondary={{
            text: actionBtn,
            variant: 'empty',
            onClick: () => onCTAClick(actionLink)
          }}
        />
      </div>
    </div>
  );
};

// --- Loading State

const LoadingState = () => {
  return (
    <div className={classnames(styles.slider, styles.loading)}>
      <div className={styles.image}>
        <Skeleton />
      </div>
      <div className={styles.content}>
        <div className={styles.main}>
          <div className={styles.title}>
            <Skeleton />
          </div>
          <div className={styles.separator} />
          <div className={styles.heading}>
            <Skeleton />
          </div>
          <div className={styles.description}>
            <Skeleton count={3} />
          </div>
        </div>
      </div>
    </div>
  );
};

// Export

export default FeatureOnboardingModal;

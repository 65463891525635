import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from 'constants/query-keys';
import { createQueryKey } from 'helpers/ReactQueryHelpers';
import { getNewFeatureNotifications } from 'services/AccountService';
import { toAccountNewFeatureNotifications } from 'services/AccountService/AccountServiceAdapter';

import type {
  IAccountNewFeatureNotification,
  IAccountNewFeatureNotificationsResponse,
  ReactQueryOptions
} from 'types';

export function useAccountNewFeatureNotifications<S = IAccountNewFeatureNotification[]>(
  options: ReactQueryOptions<IAccountNewFeatureNotificationsResponse, S> = {}
) {
  const select = React.useCallback((data: IAccountNewFeatureNotificationsResponse) => adapter(data, options?.select), [options.select]);

  return useQuery({
    queryKey: getKey(),
    queryFn: getNewFeatureNotifications,
    ...options,
    select
  });
}

// Key

function getKey() {
  return createQueryKey([
    QUERY_KEYS.ACCOUNT_NEW_FEATURE_NOTIFICATIONS
  ]);
}

// Adapter

function adapter<S = IAccountNewFeatureNotification[]>(
  data: IAccountNewFeatureNotificationsResponse,
  select?: (data: IAccountNewFeatureNotificationsResponse) => S
): S | IAccountNewFeatureNotification[] {
  if (!select) {
    return toAccountNewFeatureNotifications(data);
  }
  return select(data);
}

import React from 'react';
import classnames from 'classnames';

import { Image, Markdown } from 'components/ui';

import type { IAccountNewFeatureNotification } from 'types';

import styles from './FeatureOnboardingToastBody.module.scss';

interface Props {
  className?: string,
  notification: IAccountNewFeatureNotification,
  onClick: (notification: IAccountNewFeatureNotification) => void
}

const FeatureOnboardingToastBody = (props: Props) => {

  const {
    className,
    notification,
    onClick
  } = props;

  // Props

  const {
    text,
    icon
  } = notification || {};

  // Render

  return (
    <div
      className={classnames(styles.root, className)}
      onClick={() => {
        onClick(notification);
      }}
    >

      {/* Icon */}
      <div className={styles.icon}>
        <div className={styles.iconInner}>
          <Image src={icon} fill />
        </div>
      </div>

      {/* Text */}
      <div className={styles.text}>
        <div className={styles.textInner}>
          <Markdown text={text} />
        </div>
      </div>

    </div>
  );
};

export default FeatureOnboardingToastBody;

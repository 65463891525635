import React from 'react';

import * as Notifications from 'common/Notification';

import FeatureOnboardingToastBody from 'components/onboarding/FeatureOnboardingToastBody/FeatureOnboardingToastBody';

import type { IAccountNewFeatureNotification } from 'types';

const FEATURE_ONBOARDING_TOAST_ID_PREFIX = 'featureOnboarding';

interface ShowNewFeatureToastPayload {
  notification: IAccountNewFeatureNotification,
  onOpen: (notification: IAccountNewFeatureNotification) => void,
  onClick: (notification: IAccountNewFeatureNotification) => void,
  onDismiss: (notification: IAccountNewFeatureNotification) => void
}

// Get New Feature Toast ID

function getNewFeatureToastId(segmentId: IAccountNewFeatureNotification['segmentId']) {
  return `${FEATURE_ONBOARDING_TOAST_ID_PREFIX}_${segmentId}`;
}

// Show New Feature Toast

export function showNewFeatureToast(payload: ShowNewFeatureToastPayload) {
  const {
    notification,
    onOpen,
    onClick,
    onDismiss,
  } = payload;

  const {
    segmentId
  } = notification || {};

  const toastId = getNewFeatureToastId(segmentId);

  const toastBody = (
    <FeatureOnboardingToastBody
      notification={notification}
      onClick={onClick}
    />
  );

  if (Notifications.isActive(toastId)) {
    Notifications.update(toastId, {
      toastId,
      autoClose: false,
      onOpen: () => onOpen(notification),
      onDismiss: () => onDismiss(notification),
      render: toastBody,
    });
  } else {
    Notifications.showDefault(toastBody, {
      toastId,
      autoClose: false,
      onOpen: () => onOpen(notification),
      onDismiss: () => onDismiss(notification)
    });
  }
}

// Dismiss New Feature Toast

export function dismissNewFeatureToast(segmentId: IAccountNewFeatureNotification['segmentId']) {
  const toastId = getNewFeatureToastId(segmentId);
  Notifications.dismiss(toastId);
}

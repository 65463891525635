import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from 'constants/query-keys';
import { createQueryKey } from 'helpers/ReactQueryHelpers';
import { getNewFeature } from 'services/NewFeatureService/NewFeatureService';
import { toAccountNewFeature } from 'services/NewFeatureService/NewFeatureServiceAdapter';

import type {
  IAccountNewFeature,
  IAccountNewFeatureData,
  IAccountNewFeatureNotification,
  ReactQueryOptions
} from 'types';

export function useAccountNewFeature<S = IAccountNewFeature>(
  featureId: IAccountNewFeatureNotification['featureId'],
  options: ReactQueryOptions<IAccountNewFeatureData, S> = {}
) {
  const select = React.useCallback((data: IAccountNewFeatureData) => adapter(data, options?.select), [options.select]);
  return useQuery({
    queryKey: getKey(featureId),
    queryFn: () => getNewFeature(featureId),
    retry: 2,
    ...options,
    select
  });
}

// Key

function getKey(featureId: IAccountNewFeatureNotification['featureId']) {
  return createQueryKey([
    QUERY_KEYS.ACCOUNT_NEW_FEATURE,
    featureId
  ]);
}

// Adapter

function adapter<S = IAccountNewFeature[]>(
  data: IAccountNewFeatureData,
  select?: (data: IAccountNewFeatureData) => S
): S | IAccountNewFeature {
  if (!select) {
    return toAccountNewFeature(data);
  }
  return select(data);
}

import React from 'react';

import { areArraysEqual } from 'helpers/ArrayHelpers';
import { dismissNewFeatureToast, showNewFeatureToast } from 'helpers/OnboardingHelpers';
import { useAccountMutation } from 'hooks/data/useAccountMutation';
import { useAccountNewFeatureNotifications } from 'hooks/data/useAccountNewFeatureNotifications';
import { useUser } from 'hooks/data/useUser';
import { useAnalytics } from 'hooks/useAnalytics';

import { useIsOpen } from 'hooks/useIsOpen';

import FeatureOnboardingModal from '../FeatureOnboardingModal/FeatureOnboardingModal';

import type { IAccountNewFeatureNotification } from 'types';

type FeatureIdType = IAccountNewFeatureNotification['featureId'];
type SegmentIdType = IAccountNewFeatureNotification['segmentId'];

const OnboardingManager = () => {

  // Refs

  const notificationIdsRef = React.useRef<number[]>([]);

  // Hooks

  // --- Generic

  const analytics = useAnalytics();

  const { isOpen: isModalOpen, open: openModal, close: closeModal } = useIsOpen();

  // --- State

  const [openedNotificationIds, setOpenedNotificationIds] = React.useState<SegmentIdType[]>([]);

  const [selectedFeatureId, setSelectedFeatureId] = React.useState<FeatureIdType>(null);
  const [selectedSegmentId, setSelectedSegmentId] = React.useState<SegmentIdType>(null);

  // --- Data

  const { data: user } = useUser({ isOptional: true });

  const { shouldShowIntroNotifications } = user || {};

  const { data: notifications } = useAccountNewFeatureNotifications({
    enabled: !!shouldShowIntroNotifications
  });

  // --- Mutation

  const {
    dismissNewFeatureModal,
    dismissNewFeatureNotification
  } = useAccountMutation();

  // Handlers

  const handleNotificationSelect = (notification: IAccountNewFeatureNotification) => {
    const { segmentId, featureId, analyticsLabel } = notification || {};
    analytics.selectNewFeatureNotification(featureId, analyticsLabel);

    setSelectedFeatureId(featureId);
    setSelectedSegmentId(segmentId);

    openModal();

    dismissNewFeatureToast(segmentId);
  };

  const handleNotificationDismiss = (notification: IAccountNewFeatureNotification) => {
    const { featureId, segmentId, analyticsLabel } = notification || {};
    analytics.dismissNewFeatureNotification(featureId, analyticsLabel);
    dismissNewFeatureNotification({
      data: {
        segmentId
      }
    });
  };

  const handleModalClose = (shouldDismiss: boolean) => {
    closeModal();
    if (shouldDismiss) {
      dismissNewFeatureModal({
        data: {
          segmentId: selectedSegmentId
        }
      });
    }
  };

  const handleAfterModalClose = () => {
    setSelectedFeatureId(null);
    setSelectedSegmentId(null);
  };

  // Effects

  // --- Open notifications

  React.useEffect(() => {
    if (!notifications) return;

    const notificationIds = notifications.map(({ segmentId }) => segmentId);

    if (areArraysEqual(notificationIdsRef?.current, notificationIds)) return;

    notifications.forEach((notification) => {
      const { segmentId } = notification || {};
      if (notificationIdsRef.current.includes(segmentId)) return;
      notificationIdsRef.current.push(segmentId);

      showNewFeatureToast({
        notification,
        onClick: handleNotificationSelect,
        onOpen: (openedNotification) => {
          const { featureId, analyticsLabel: featureAnalyticsLabel } = openedNotification;
          analytics.viewNewFeatureNotification(featureId, featureAnalyticsLabel);
        },
        onDismiss: () => handleNotificationDismiss(notification)
      });
    });
  }, [notifications]);

  // --- Close all notifications on unmount

  React.useEffect(() => {
    return () => {
      notificationIdsRef.current.forEach((segmentId) => {
        dismissNewFeatureToast(segmentId);
      });
    };
  }, []);

  // Render

  return (
    <FeatureOnboardingModal
      isOpen={isModalOpen}
      //
      featureId={selectedFeatureId}
      //
      onClose={handleModalClose}
      onAfterClose={handleAfterModalClose}
    />
  );

};

export default OnboardingManager;
